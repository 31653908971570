<template>
  <div class='menu'>
    <div class='dvi'
      :class='{ "is-active": active }'>
      <i class='hya icon'
        @click='$emit("close")'>
        close
      </i>
      <img class='yia'
        src='images/0af7.png'>
    </div>
    <div class='dre'
      :class='{ "is-active": active }'>
      <router-link class='ryg'
        v-show='user && !user.isAnonymous'
        :to='{ name: "profile" }'
        @mouseup='$emit("close")'>
        My Profile
      </router-link>
      <router-link class='ryg'
        :to='{ name: "home" }'
        @mouseup='$emit("close")'>
        What is CIMM?
      </router-link>
      <router-link class='ryg'
        :to='{ name: "maturity-analysis" }'
        @mouseup='$emit("close")'>
        Maturity Analysis
      </router-link>
      <router-link class='ryg'
        :to='{ name: "test" }'
        @mouseup='$emit("close")'>
        Take CIMM Assessment
      </router-link>
      <router-link class='ryg'
        :to='{ name: "results" }'
        @mouseup='$emit("close")'>
        View Report History
      </router-link>
      <a class='ryg'
        target='_blank'
        href='https://www.riseaccel.com/about-rise'>
        About RISE
      </a>
      <a class='ryg'
        target='_blank'
        href='https://share.hsforms.com/1QlSeVVYIQtyB_5P3ZNsTwA3iysw'>
        Contact Us
      </a>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  export default {
    props: {
      active: Boolean
    },

    emits: ['close'],

    setup () {
      let store = useStore()
      let user = computed(() => store.state.user.user)
      return { user }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .dvi {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: map.get(variables.$elevations, 'drawer') + 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 80px;
    background-color: white;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out 0.2s;

    &.is-active {
      transform: translateY(0);
    }
  }

  .hya {
    margin-top: 4px;
    cursor: pointer;
  }

  .yia {
    margin-top: -20px;
    height: 40px;
  }

  .dre {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map.get(variables.$elevations, 'drawer');
    overflow-y: auto;
    padding: 88px 0 8px;
    background-color: map.get(variables.$colors, 'gray', '100');
    transform: translateY(-100%);
    transition: transform 0.4s ease-in-out;

    &.is-active {
      transform: translateY(0);
    }
  }

  .ryg {
    display: block;
    overflow: hidden;
    padding: 16px 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .dvi {
      padding: 0 32px;
      height: 96px;
    }

    .hya {
      margin-top: 8px;
    }

    .yia {
      margin-top: -24px;
      height: 48px;
    }

    .dre {
      padding: 112px 0 16px;
    }

    .ryg {
      padding: 16px 32px;
    }
  }
</style>
