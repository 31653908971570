<template>
  <template v-if='header'>
    <header-component/>
  </template>
  <div class='main'>
    <div class='qqp'>
      <router-view/>
    </div>
  </div>
  <footer-component/>
</template>

<script>
  import { computed, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import Footer from '@/components/footer/footer.vue'
  import Header from '@/components/header/header.vue'
  import { WATCH_USER } from '@/store/action-types'

  export default {
    components: {
      'footer-component': Footer,
      'header-component': Header
    },

    inheritAttrs: false,

    setup () {
      let route = useRoute()
      let store = useStore()
      let header = computed(() => route.meta?.layout?.header)

      onMounted(() => {
        store.dispatch(WATCH_USER)
      })

      return { header }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .main {
    padding: 24px;
  }

  .qqp {
    margin: auto;
    max-width: 960px;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .main {
      padding: 32px;
    }
  }
</style>
