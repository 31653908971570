<template>
  <div class='header'>
    <div class='aph'>
      <i class='tua icon'
        @click='menu = !menu'>
        menu
      </i>
      <a class='rce'
        href=''>
        <img class='rjy'
          src='images/0af7.png'>
      </a>
      <div class='jbb'>
        <template v-if='user || user === null'>
          <template v-if='!user || user.isAnonymous'>
            <router-link
              :to='{ name: "login" }'>
              <button-element
                rounded
                outlined>
                Log In
              </button-element>
            </router-link>
            <router-link class='cek'
              :to='{ name: "register" }'>
              <button-element
                rounded>
                Sign Up
              </button-element>
            </router-link>
          </template>
          <template v-else>
            <button-element
              rounded
              outlined
              @click='signOut'>
              Log Out
            </button-element>
          </template>
        </template>
        <template v-else>
          <progress-element active/>
        </template>
      </div>
    </div>
    <menu-component
      :active='menu'
      @close='menu = false'/>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import { useStore } from 'vuex'
  import { SIGN_OUT } from '@/store/action-types'
  import Menu from './menu.vue'

  export default {
    components: {
      'menu-component': Menu
    },

    setup () {
      let store = useStore()
      let menu = ref(false)
      let user = computed(() => store.state.user.user)

      let signOut = async () => {
        await store.dispatch(SIGN_OUT)
        location.reload()
      }

      return {
        menu,
        user,
        signOut
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .header {
    height: 80px;
  }

  .aph {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map.get(variables.$elevations, 'app-bar');
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 80px;
    background-color: white;
  }

  .tua {
    margin-top: 4px;
    cursor: pointer;
  }

  .rce {
    margin: -20px 0 0 24px;
  }

  .rjy {
    display: block;
    height: 40px;
  }

  .jbb {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .cek {
    display: none;
  }

  @media (min-width: map.get(variables.$breakpoints, 'xs')) {
    .cek {
      display: block;
      margin-left: 8px;
    }
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .header {
      height: 96px;
    }

    .aph {
      padding: 0 32px;
      height: 96px;
    }

    .tua {
      margin-top: 8px;
    }

    .rce {
      margin: -24px 0 0 32px;
    }

    .rjy {
      height: 48px;
    }
  }
</style>
