<template>
  <div class='footer'
    :style='{ marginTop: `${top}px` }'>
    <div>Copyright © 2021 RISE All rights reserved.</div>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted } from 'vue'

  export default {
    setup () {
      let top = ref(0)
      let ticking = false

      let adjustPosition = () => {
        if (ticking) return

        ticking = true
        requestAnimationFrame(() => {
          let windowHeight = window.innerHeight
          let height = document.body.offsetHeight - top.value + 1
          top.value = height < windowHeight
            ? windowHeight - height
            : 0
          ticking = false
        })
      }

      let observer = new ResizeObserver(adjustPosition)

      onMounted(() => {
        observer.observe(document.body)
        window.addEventListener('resize', adjustPosition)
      })

      onUnmounted(() => {
        observer.disconnect()
        window.removeEventListener('resize', adjustPosition)
      })

      return { top }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .footer {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 56px;
    color: white;
    background-color: map.get(variables.$colors, 'gray', '700');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .footer {
      padding: 0 32px;
    }
  }
</style>
